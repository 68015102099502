@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@200&display=swap');

body{
    margin: 0;
    padding:0;
    font-family: 'Roboto Mono', monospace;
}

@tailwind base;
@tailwind components;
@tailwind utilities;